
import { Component, Vue, Ref } from 'vue-property-decorator'
import { $post } from '@/plugins/axios'
import { HandleError } from '@/utils/helper'
import { PageModule } from '@/store/page'
// components
import LoginCard from '@/components/authorization/LoginCard.vue'
import RegisterCard from '@/components/authorization/RegisterCard.vue'
import AuthUserView from '@/components/authorization/AuthUserView.vue'
import AuthSkeleton from '@/components/authorization/AuthSkeleton.vue'
import SetPassword from '@/components/authorization/SetPassword.vue'
import { UserModule } from '@/store/user'
// background images
const a101Image = require('@/assets/a101.jpg')
const smltImage = require('@/assets/smlt.jpg')
const defaultImage = require('@/assets/login.jpg')

export type AuthStep = 'auth-skeleton' | 'login-card' | 'register-card' | 'auth-user-view' | 'set-password';
export type AuthType = 'sms' | 'call' | 'password';

export enum API_ROUTES {
  'CODE' = '/auth/code',
  'AUTH' = '/auth'
}

export interface CodeResponse {
  message: string;
  isDemo: boolean;
  timeout: number;
  title: string;
  type: AuthType
}

export interface AuthResponse {
  token: string;
  needPassword: boolean;
}

@Component({
  components: {
    LoginCard,
    RegisterCard,
    AuthUserView,
    AuthSkeleton,
    SetPassword
  }
})
export default class Authorization extends Vue {
  @Ref('login-card') login!: LoginCard;
  // data
  currentStep: AuthStep = 'auth-skeleton'

  // getters
  get isA101(): boolean {
    const hosts = ['app.a101.ru', 'lk-a101.domyland.dev']
    return hosts.includes(window.location.host)
  }

  get isSmlt(): boolean {
    const hosts = ['lk-uk.samolet.ru', 'lk-samolet.domyland.dev']
    return hosts.includes(window.location.host)
  }

  get backgroundImage(): string {
    if (this.isA101) return a101Image
    else if (this.isSmlt) return smltImage
    return defaultImage
  }

  get color():string {
    if (this.isA101) return 'a101'
    else if (this.isSmlt) return 'smlt'
    return 'primary'
  }

  // actions
  setCurrentStep(step: AuthStep): void {
    this.currentStep = step
  }

  handleAuthType(type: AuthType): void {
    switch (type) {
      case 'call':
      case 'sms': {
        this.login.showCodeField = true
        break
      }
      case 'password': {
        this.login.showPasswordField = true
        break
      }
    }
  }

  async askForAuth(body: {phoneNumber: string, captcha:string; forgotPassword: boolean }): Promise<void> {
    try {
      const { data } = await $post<CodeResponse>(API_ROUTES.CODE, body)
      this.handleAuthType(data.type)
      if (data.type !== 'password') PageModule.SEND_NOTIFICATION({ type: 'success', message: data.message })
    } catch (e) {
      if (e.response.data.errorCode === 429) { this.login.showCaptcha = true } else { HandleError(e) }
    }
  }

  async logIn(body: { password?: string; code?: string; phoneNumber: string; captcha?: string }): Promise<void> {
    try {
      this.login.captcha = ''
      this.login.showCaptcha = false

      const { data } = await $post<AuthResponse>(API_ROUTES.AUTH, body)
      localStorage.setItem('token', data.token)
      if (data.needPassword) {
        this.setCurrentStep('set-password')
        return
      }
      await this.handleAuthorizedUser()
    } catch (e) {
      if (e.response.data.errorCode === 429) { this.login.showCaptcha = true }
      HandleError(e)
    }
  }

  async handleAuthorizedUser(): Promise<void> {
    const token = localStorage.getItem('token')
    if (token) {
      try {
        const user = await UserModule.getUser()

        if (!user.places.length) {
          this.currentStep = 'auth-user-view'
          return
        }

        await PageModule.initPage()
        await this.$router.push('/services')
        return
      } catch (e) {
        HandleError(e)
      }
    } else {
      this.currentStep = 'login-card'
    }
  }

  created(): void {
    this.handleAuthorizedUser()
    localStorage.removeItem('placeId')
    localStorage.removeItem('buildingId')
  }

  mounted(): void {
    if (localStorage.getItem('phone')) {
      this.login.phoneNumber = localStorage.getItem('phone')!.replace(/\D+/g, '')
      this.login.showLoginField = false
      this.login.showAgreement = false
      this.login.agreement = true
      this.login.title = 'Восстановить пароль'
      this.login.buttonTitle = 'Отправить'
      this.login.handleForgotPassword()
      localStorage.removeItem('phone')
    }
  }
}
